const allowedRouteRoles = {
  events: ['TRADER', 'INTERNAL_OPS', 'OPERATOR_ADMIN', 'SUPER_ADMIN', 'DPS_TRADER'],
  templates: ['TRADER', 'INTERNAL_OPS', 'OPERATOR_ADMIN', 'SUPER_ADMIN'],
  manualResulting: ['INTERNAL_OPS', 'OPERATOR_ADMIN', 'SUPER_ADMIN'],
  mapping: ['TRADER', 'INTERNAL_OPS', 'OPERATOR_ADMIN', 'SUPER_ADMIN'],
  playerSetup: ['TRADER', 'INTERNAL_OPS', 'SUPER_ADMIN'],
  tradingUI: ['TRADER', 'INTERNAL_OPS', 'OPERATOR_ADMIN', 'SUPER_ADMIN'],
  marketDisplay: ['INTERNAL_OPS', 'OPERATOR_ADMIN', 'SUPER_ADMIN'],
  marketGroups: ['INTERNAL_OPS', 'OPERATOR_ADMIN', 'SUPER_ADMIN'],
  marketConfig: ['INTERNAL_OPS', 'OPERATOR_ADMIN', 'SUPER_ADMIN'],
  manualEdit: ['INTERNAL_OPS', 'SUPER_ADMIN'],
  oddsChecker: ['TRADER', 'INTERNAL_OPS', 'SUPER_ADMIN'],
  betTicker: ['TRADER', 'INTERNAL_OPS', 'OPERATOR_ADMIN', 'SUPER_ADMIN'],
  competitionConfiguration: ['INTERNAL_OPS', 'OPERATOR_ADMIN', 'SUPER_ADMIN'],
  selectionDisplay: ['INTERNAL_OPS', 'OPERATOR_ADMIN', 'SUPER_ADMIN'],
  liability: ['TRADER', 'INTERNAL_OPS', 'OPERATOR_ADMIN', 'SUPER_ADMIN'],
};

export const getAllowedRoles = (route) => allowedRouteRoles[route] || [];
